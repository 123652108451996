body {
	.glightbox-clean {
		.gprev {
			/*top: 45%;
			background: none;
			left: 1rem;
			opacity: 0.5;*/
			display: none;
		}

		.gnext {
			/*top: 45%;
			background: none;
			right: 1rem;
			opacity: 0.5;*/
			display: none;
		}

		.gprev, .gnext {
			&:hover, &:active, &:focus {
				background: none;
				opacity: 1;
			}
		}

		.gclose {
			background: none;
			opacity: 0.8;

			svg {
				width: 20px;
				height: 20px;
				filter: none !important;
			}

			&:hover, &:active, &:focus {
				background: none;
				opacity: 1;
			}
		}

		.goverlay {
			background: rgba($black, 0.5);
		}

		.gslide-media {
			box-shadow: none;
			position: relative;
		}
	}

	.gslide-inline {
		background: none;
		width: auto !important;

		.ginlined-content {
			padding: 0;
		}
	}
}

.glightbox-open {
	.content, .header, .footer {
		filter: blur(5px);
	}
}

@media screen and (max-width: 970px) {
	body {
		.glightbox-modal .gclose {
			svg {
				filter: invert(100%);
			}
		}
	}
}