.sh-content { display: none; }

.info-panel { color: $white; }
.partners .partner-item img { width: 80%; }
.partners-groups .partner-gold img { max-width: 80%; }

.entry svg { width: 100%; height: auto; }
.entry p:last-child { margin-bottom: 0; }

.entry-warning-text a { color: #fff; text-decoration: underline; }
.entry-warning-text { a:hover, a:active { color: #fff; text-decoration: none; } }

.button-menu .btn img { height: 2rem; width: auto; }

.img-wrap img { max-width: 100%; display: block; }

.modal-list { h2, h3 { &:not(:first-child) { margin-top: 2rem; } } }

ul li::before { background-image: url(/images/icon_list_bullet_base.svg); }
ul.check li::before { background-image: url(/images/icon_list_bullet_check.svg); }

.weather-wrap { width: 100%; }
.weather-wrap-header { position: absolute; top: 0; width: 225px; height: 98px; margin-left: -150px; margin-top: -56px; transform: scale(0.5); }
.view-item-weather .weather-wrap-inner { padding: 0 50px; }
.weather-wrap-inner .weatherwidget-io { transform: scale(1.3) translateY(5px); }

.view-item-weather { width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; }

.table-event td { line-height: 1.5; }

.table-wrap-editmode { max-width: 50rem; overflow-x: visible; }
.table-wrap-editmode table { width: 100%; }
.table-wrap-editmode table { th, td { border-right: 1px solid #9a9a9a; } }

.table-wrap-auto { max-width: none; }
.table th p, .table td p { margin-bottom: 0; }
.table-wrap, .table-wrap-cke {
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	table {
		width: 100%; border-collapse: collapse;

		td,
		th {
			min-width: 10rem;

			text-align: center;
			&:last-child {
				text-align: right;
			}
			&:first-child {
				text-align: left;
			}

			p {
				margin-bottom: 0 !important;
			}
		}

		thead th {
			font-weight: 500;
			padding: 0.75rem;
		}
		td {
			border-top: 1px solid #9a9a9a;
			padding: 0.75rem;
		}
		tbody tr:hover {
			background-color: rgba(0, 0, 0, 0.075);
		}
		h1, h2, h3, h4, h5, h6, p, ul, ol {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.table-auto-cols {
		td, th {
			min-width: 0;
		}
	}
}

.trainer-table h4 { margin-top: 0; }
.trainer-img img { width: 100%; height: auto; }

.container img, .container-lg img, .container-sm img { max-width: 100%; height: auto; }
.category-item img { max-width: 100%;  height: auto;}
.action-item img { max-width: 100%;  height: auto;}
.card img { max-width: 100%;  height: auto;}
.img-teaser img { max-width: 100%;  height: auto;}
.gallery img { max-width: 100%; width: 100%; height: auto;}
.video img { max-width: 100%; height: auto; }
.video iframe { max-width: 100%; }

.registration-panel-price:not(:last-child) { margin-bottom: 5px; }

.action-list-more .action-teaser:first-child { border-top: none; }

.mastership-rules:not(:last-child) .mastership-text { border-bottom: 1px solid #6f7e74; }
.mastership-rules p:last-child { margin-bottom: 0; }

.mastership-Gallery .gallery { display: flex; flex-wrap: wrap; margin-left: -1rem; }
.mastership-Gallery .gallery a { display: block; padding-left: 1rem; margin-bottom: 1rem; width: 33.33333%; }
.mastership-Gallery .gallery a img { display: block; width: 100%; }

.partners-head-link { color: inherit; text-decoration: none; }
.partners-head-link { &:hover, &:active { text-decoration: none; } }

.action-calendar-link { margin-bottom: 1rem; }

::-webkit-scrollbar {
    -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
    width: 12px;
}

::-webkit-scrollbar:horizontal {
    height: 12px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(#6f7e74, .5);
    border-radius: 10px;
    border: 2px solid #ffffff;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
}

@media screen and (max-width: 1200px) {
	.view-item-weather .weather-wrap-inner { padding: 30px 100px; }
}

@media screen and (max-width: 991px) {
	.weather-wrap-inner .weatherwidget-io { transform: scale(1.3); }

	.video iframe { height: 50vw; }
}

@media screen and (max-width: 771px) {
	.mastership-Gallery .gallery a { width: 100%; }
	.view .view-item { height: auto; }
}

@media screen and (max-width: 576px) {
	.weather-wrap-inner { padding: 0 50px 30px 50px; }
	.view-item-weather .weather-wrap-inner { padding: 30px 100px; }
}