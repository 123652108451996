.modal {
	display: none;
	background: $white;
	position: relative;

	&__close {
		position: absolute;
		right: 1.1rem;
		top: 1.2rem;
		display: block;
		border: none;
		background: none;
		transition: transform $transitionsSpeedTiming;

		.icon {
			width: 1.1rem;
			height: 1.1rem;
		}

		&:hover, &:active, &:focus {
			transform: rotate(90deg);
		}
	}

	&--event {
		background: none;
		width: auto !important;
		max-width: 100% !important;
	}

	&__content {
		padding: 2.5rem 1.5rem 2rem 1.5rem;
	}

	&__img {
		max-width: 100%;

		img {
			max-width: 100%;
			width: auto;
			max-height: calc(100vh - 4rem);
			height: auto;
		}
	}

	&__link {
		display: block;
		width: 100%;
		height: 100%;
	}
}

@media screen and (max-width: 750px) {
	.modal {
		&__content {
			padding: 0.75rem 0.5rem;
		}
	}
}